import React from 'react';
import { useTranslation } from 'react-i18next';
import { Country } from 'country-state-city';
import { getFlag } from '../../../lib/getFlag';

type Business = {
  headquarter: string;
  city: string;
  state: string;
  websiteUrl: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
};

interface LocationCategoryProps {
  user;
  withLocation?: boolean;
  textSize?: string;
  business?: Business;
}

export default function LocationCategory({
  user,
  withLocation = false,
  textSize = 'text-sm',
  business = null,
}: LocationCategoryProps) {
  const { i18n } = useTranslation();
  const language = i18n?.language ?? 'en';

  const countryFlag =
    getFlag(user?.profile?.address?.country) ?? getFlag(business?.headquarter);

  let city;
  let country;

  if (user?.profile?.address) {
    city = user.profile.address.city;
    country = Country.getCountryByCode(user.profile.address.country)?.name;
  } else if (business) {
    city = business.city;
    country = Country.getCountryByCode(business.headquarter)?.name;
  } else {
    city = '';
    country = '';
  }

  const AddressString = `${city}, ${country}`;

  const sortedCategories = React.useMemo(
    () =>
      user?.profile?.categories?.sort((a, b) => {
        const nameA = a.name[language].toUpperCase();
        const nameB = b.name[language].toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }),
    [user?.profile?.categories]
  );

  return (
    <div className={`${textSize} gap-1 w-full text-left`}>
      {withLocation ? (
        <span className="whitespace-nowrap overflow-hidden text-ellipsis">
          {sortedCategories[0]?.name[language]}
        </span>
      ) : null}
      <div className="gap-1 flex w-full">
        <span className="">{countryFlag}</span>
        <span className="whitespace-nowrap overflow-hidden text-ellipsis w-full">
          {withLocation ? AddressString : sortedCategories[0]?.name[language]}
        </span>
      </div>
    </div>
  );
}
